import React from 'react';

import A from '../../core/a';
import SectionContainer from '../../core/section-container';

const Others = () => {
  return (
    <>
      <SectionContainer>
        <ul className="text-sm">
          <li>
            <A href="https://blog.samaltman.com/hard-startups/" text="Hard Startups (Sam Altman)" />
          </li>
          <li>
            <A href="http://paulgraham.com/newideas.html" text="Crazy New Ideas (Paul Graham)" />
          </li>
          <li>
            <A
              href="https://www.gatesnotes.com/Books/A-Thousand-Brains"
              text="Is this how your brain works? (Bill Gates)"
            />
          </li>
        </ul>
      </SectionContainer>
    </>
  );
};

export default Others;
