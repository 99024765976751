import React from 'react';

import A from 'components/core/a';
import Layout from 'components/core/layout';
import P from 'components/core/p';
import Seo from 'components/core/seo';
import Span from 'components/core/span';

import LinkList from 'components/pages/links/list-section';

const Links: React.FC = () => (
  <Layout>
    <Seo title="Bookmarks" />
    <P>
      I am adding random things that I found interesting. Any recommendations?{' '}
      <Span>
        <A href="mailto: ctuncelli@yahoo.com" text="Let me know." />
      </Span>
    </P>
    <LinkList />
  </Layout>
);

export default Links;
